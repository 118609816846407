import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ReviewModel } from './review.model';
import { ReviewService } from './review.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewResolverService implements Resolve<ReviewModel> {

  constructor(private reviewService: ReviewService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ReviewModel> {
    const reviewId = route.paramMap.get('reviewId')!;

    return this.reviewService.getReview(+reviewId);
  }
}