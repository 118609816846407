import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@shared/api/base-api-service';
import { Observable } from 'rxjs';
import { Education } from './models/education.interface';
import { Job } from './models/job.interface';
import { Knowledge } from './models/knowledge.interface';
import { UserDetail } from './models/user-detail.model';
import { IUserDetailResponse } from './user-detail-response.interface';
import { UserAbout } from './models/user-about.interface';

@Injectable({
  providedIn: 'root'
})
export class UserDetailBackendService extends BaseApiService {

  constructor(protected http: HttpClient) {
    super(http, '/user-detail');
  }

  public getUserDetail(): Observable<UserDetail> {
    return this.get<UserDetail>('');
  }

  public saveUserDetail(userDetail: UserDetail): Observable<IUserDetailResponse> {
    return this.put<IUserDetailResponse>('', userDetail);
  }

  public saveAbout(about: UserAbout): Observable<IUserDetailResponse> {
    return this.put<IUserDetailResponse>('about', about);
  }

  public saveEducation(education: Education[]): Observable<IUserDetailResponse> {
    return this.put<IUserDetailResponse>('education', {education});
  }

  public saveKnowledge(knowledge: Knowledge): Observable<IUserDetailResponse> {
    return this.put<IUserDetailResponse>('knowledge', knowledge);
  }

  public saveJobs(jobs: Job[]): Observable<IUserDetailResponse> {
    return this.put<IUserDetailResponse>('job', {jobs});
  }
}
