import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@shared/api/base-api-service';
import { ICropImageData } from '@shared/directives/cropped-photo-uploader/crop-image-data.interface';
import { FileUploaderService } from '@shared/services/file-uploader.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, filter, shareReplay, tap } from 'rxjs/operators';
import { Sponsor } from './sponsor.namespace';
import ChartDataResponse = Sponsor.ChartDataResponse;


@Injectable({
  providedIn: 'root'
})
export class SponsorService extends BaseApiService {

  private readonly _profile$: BehaviorSubject<Sponsor.Sponsor | null> = new BehaviorSubject(null);

  public readonly profile$: Observable<Sponsor.Sponsor> = <Observable<Sponsor.Sponsor>>this._profile$.asObservable().pipe(
    filter(value => value !== null)
  );

  private metrics$: Observable<ChartDataResponse>;

  private metricsDates: { dateFrom: string, dateTo: string } | null = null;

  constructor(protected http: HttpClient,
              private fileUploaderService: FileUploaderService) {
    super(http, '/sponsors');
  }

  public getProfile(): Observable<Sponsor.Sponsor> {
    if (!this._profile$.value) {
      return this.get<Sponsor.Sponsor>('/profile').pipe(
        shareReplay(),
        tap(profile => this._profile$.next(new Sponsor.Sponsor(profile))),
        concatMap(() => this.profile$)
      )
    } else {
      return this.profile$;
    }
  }

  public saveProfile(profile: Sponsor.Sponsor, isCreate: boolean = false): Observable<Sponsor.Sponsor> {
    let saveObservable$: Observable<Sponsor.Sponsor>;

    if (!isCreate) {
      saveObservable$ = this.put<Sponsor.Sponsor>('', profile)
    } else {
      saveObservable$ = this.post<Sponsor.Sponsor>('', profile)
    }

    return saveObservable$.pipe(tap(profile => this._profile$.next(new Sponsor.Sponsor(profile))));
  }

  public uploadLogo(logo: ICropImageData): Observable<any> {
    return this.fileUploaderService.upload(logo, this.getApiUrl('update-logo'));
  }

  public getMetrics(dates: { dateFrom: string, dateTo: string } | null = null): Observable<ChartDataResponse> {
    if (!this.metrics$ || this.isMetricsDatesChanged(dates)) {
      let options: { params?: { dateFrom: string, dateTo: string } } = {};

      if (dates) {
        options.params = dates;
      }

      this.metricsDates = dates;

      this.metrics$ = this.get<ChartDataResponse>('/metrics', options).pipe(shareReplay());
    }

    return this.metrics$;
  }

  private isMetricsDatesChanged(dates: { dateFrom: string, dateTo: string } | null): boolean {
    if (this.metricsDates === null) {
      return dates !== null;
    }

    if (dates === null) {
      return this.metricsDates !== null;
    }

    return this.metricsDates.dateFrom !== dates.dateFrom || this.metricsDates.dateTo !== dates.dateTo;
  }
}