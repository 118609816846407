import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractPaginationStore } from '@shared/components/pagination/abstract-pagination.store';
import { IPaginationOptions } from '@shared/components/pagination/pagination-options.interface';
import { IPagination } from '@shared/components/pagination/pagination.interface';
import { IResponseOk } from '@shared/models/api-response/response-ok.interface';
import { Observable } from 'rxjs';
import { concatMap, map, take, tap } from 'rxjs/operators';
import { IReviewListItem, IReviewNewItem } from './review.interface';
import { ReviewModel } from './review.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends AbstractPaginationStore<IReviewListItem> {

  constructor(protected http: HttpClient) {
    super(http, '/contact-review');
  }

  public create(review: IReviewNewItem, contactId: number): Observable<IResponseOk> {
    return this.post<IResponseOk>(`/${contactId}`, review)
      .pipe(
        concatMap(res => this.getReviews({page: '1'}, contactId).pipe(map(() => res))),
        take(1)
      );
  }

  public getReviews(paginationOptions: IPaginationOptions, contactId: number): Observable<IPagination<IReviewListItem>> {
    return super.getItems(paginationOptions, `/${contactId}/list`);
  }

  public share(review: IReviewListItem): Observable<ReviewModel> {
    return this.post<any>(`/${review.id}/share`, review)
      .pipe(
        tap(res => {
          let reviews = this._items$.value;

          let review = reviews.items.find(review => +review.id === +res.id);

          if (review) {
            Object.assign(review, res);
          }
        })
      );
  }

  public getReview(reviewId: number): Observable<ReviewModel> {
    return this.get<ReviewModel>(`/${reviewId.toString()}/view`).pipe(map(review => new ReviewModel(review)));
  }
}
