import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ModalsService } from '@shared/modals/modals.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ProfileStore } from '../profile/profile.store';

@Injectable({
  providedIn: 'root'
})
export class CanActivatePremiumGuard implements CanActivate {

  constructor(private profileStore: ProfileStore,
              private modalsService: ModalsService) {
  }

  public canActivate(): Observable<boolean> {
    return this.profileStore.authenticatedProfile$.pipe(
      take(1),
      map(profile => {

        if (!profile.premium) {
          const description = profile.isEntrepreneur ?
            '_premium._need.recommended.modal.info_entrepreneur' :
            '_premium._need.recommended.modal.info_partner';

          const title = '_premium._need.recommended.modal.title';

          this.modalsService.openPremiumInfo(title, description);
        }

        return profile.isPremium;
      }),
    )
  }

}