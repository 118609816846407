import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractPaginationStore } from '@shared/components/pagination/abstract-pagination.store';
import { ContactModel } from './contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsStore extends AbstractPaginationStore<ContactModel> {
  constructor(protected http: HttpClient) {
    super(http, '/network/contacts');
  }

  protected createItem(item: ContactModel): ContactModel {
    return new ContactModel(item);
  }
}
