import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ProfileHttpService } from '@shared/api/profile-http.service';
import { ProfileInfoBase } from '@shared/models/account/profile/profile-info-base.model';
import { Observable, of } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { ContactsStore } from '../contacts/contacts.store';

@Injectable({
  providedIn: 'root'
})
export class ProfileInfoBaseResolverService implements Resolve<ProfileInfoBase> {

  constructor(private store: ContactsStore,
              private profileBackendService: ProfileHttpService,
              private router: Router) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ProfileInfoBase> {
    let contactId = route.paramMap.get('id') || route.paramMap.get('slugOrId');

    return this.store.items$.pipe(
      take(1),
      map(contacts => {
        if (contactId === null) {
          this.router.navigate(['/network/contacts']);
          return null;
        } else {
          return contacts
        }
      }),
      map(contacts => contacts && contacts.items.find(contact => +contact.id === +contactId!)),
      concatMap(contact => !!contact ? of(contact) : this.profileBackendService.getContact(contactId!))
    )
  }
}
