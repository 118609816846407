import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, shareReplay, tap } from 'rxjs/operators';
import { Education } from './models/education.interface';
import { Job } from './models/job.interface';
import { Knowledge } from './models/knowledge.interface';
import { UserDetail } from './models/user-detail.model';
import { UserDetailBackendService } from './user-detail-backend.service';
import { IUserDetailResponse } from './user-detail-response.interface';
import { UserAbout } from './models/user-about.interface';

@Injectable({
  providedIn: 'root'
})
export class UserDetailStore {

  private readonly _userDetail$: BehaviorSubject<UserDetail> = new BehaviorSubject<UserDetail>(<any>null);

  public userDetail$: Observable<UserDetail> = this._userDetail$.asObservable();

  constructor(private backendService: UserDetailBackendService) {
  }

  public getUserDetail(): Observable<UserDetail> {
    if (this._userDetail$.value) {
      return this.userDetail$;
    }

    return this.backendService.getUserDetail()
      .pipe(
        shareReplay(),
        tap(userDetail => !this._userDetail$.value && this._userDetail$.next(new UserDetail(userDetail))),
        concatMap(() => this.userDetail$)
      );
  }

  public reloadUser(): void {
    this.backendService.getUserDetail()
      .subscribe(userDetail => this._userDetail$.next(new UserDetail(userDetail)));
  }

  public saveAbout(about: UserAbout): Observable<IUserDetailResponse> {
    return this.updateUserDetailStream(this.backendService.saveAbout(about));
  }

  public saveEducation(education: Education[]): Observable<IUserDetailResponse> {
    return this.updateUserDetailStream(this.backendService.saveEducation(education));
  }

  public saveKnowledge(knowledge: Knowledge): Observable<IUserDetailResponse> {
    return this.updateUserDetailStream(this.backendService.saveKnowledge(knowledge));
  }

  public saveJobs(jobs: Job[]): Observable<IUserDetailResponse> {
    return this.updateUserDetailStream(this.backendService.saveJobs(jobs));
  }

  public saveUserDetail(userDetail: UserDetail): Observable<IUserDetailResponse> {
    return this.updateUserDetailStream(this.backendService.saveUserDetail(userDetail));
  }

  private updateUserDetailStream(request$: Observable<IUserDetailResponse>): Observable<IUserDetailResponse> {
    return request$.pipe(tap(response => this._userDetail$.next((new UserDetail(response.user)))));
  }
}
