import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagination } from '@shared/components/pagination/pagination.interface';
import { ProfileInfoBase } from '@shared/models/account/profile/profile-info-base.model';
import { Observable } from 'rxjs';
import { IProjectListItem } from '../../projects/project-list-item.interface';
import { ICropImageData } from '../directives/cropped-photo-uploader/crop-image-data.interface';
import { IConnector } from '../models/account/profile/partnership/connector.interface';
import { IResponseOk } from '../models/api-response/response-ok.interface';
import { FileUploaderService } from '../services/file-uploader.service';
import { BaseApiService } from './base-api-service';

export interface IConnectorData {
  type_id: number,
  organization: string,
  contact_name: string,
  phone: string,
  email?: string,
  password?: string,
  city: any,
  web_site: string,
  soc_fb: string,
  soc_lin: string,
  soc_tw: string,
  desc: string,
}

@Injectable({
  providedIn: 'root'
})
export class ConnectorService extends BaseApiService {

  constructor(protected http: HttpClient,
              private fileUploader: FileUploaderService) {
    super(http, 'connector');
  }

  getProfile(): Observable<IConnector> {
    return this.get<IConnector>('profile');
  }

  save(data: IConnectorData): Observable<IConnector> {
    return this.put<IConnector>('', data);
  }

  register(data: IConnectorData): Observable<IConnector> {
    return this.post<IConnector>('', data);
  }

  publish(): Observable<IResponseOk> {
    return this.put<IResponseOk>('publish', {});
  }

  uploadLogo(data: ICropImageData): Observable<IResponseOk> {
    return this.fileUploader.upload<IResponseOk>(data, this.getApiUrl('update-logo'));
  }

  getProjects(id: number, sort: string = 'id'): Observable<IPagination<IProjectListItem>> {
    return this.get<IPagination<IProjectListItem>>(`${id}/members/projects`, {
      params: {sort: sort.toString()}
    });
  }

  getPartners(id: number, sort: string = 'id'): Observable<IPagination<ProfileInfoBase>> {
    return this.get<IPagination<ProfileInfoBase>>(`${id}/members/partners`, {
      params: {sort: sort.toString()}
    });
  }

  getEntrepreneurs(id: number, sort: string = 'id'): Observable<IPagination<ProfileInfoBase>> {
    return this.get<IPagination<ProfileInfoBase>>(`${id}/members/entrepreneurs`, {
      params: {sort: sort.toString()}
    });
  }

  lastShareNews(id: number, count: number = 10): Observable<any> {
    return this.get<any>(`${id}/shares/${count}`);
  }

  public liveSearch(query: string): Observable<any[]> {
    return this.get<any[]>('live-search', {params: {query}});
  }
}
